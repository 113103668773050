import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage3Tooted from "./pages/Webpage3Tooted";
import Webpage4Ajurveeda from "./pages/Webpage4Ajurveeda";
import Webpage5KONTAKT from "./pages/Webpage5KONTAKT";
import Webpage6Konsultatsioon from "./pages/Webpage6Konsultatsioon";
import Webpage7Teraapiad from "./pages/Webpage7Teraapiad";
import Webpage8Toode from "./pages/Webpage8Toode";
import Webpage9Ajurveeda_soovitab from "./pages/Webpage9Ajurveeda_soovitab";
import Webpage10M____gitingimused from "./pages/Webpage10M____gitingimused";
import Webpage11Privaatsuspoliitika from "./pages/Webpage11Privaatsuspoliitika";
import Webpage12Soovitused_kevadeks from "./pages/Webpage12Soovitused_kevadeks";
import Webpage13Soovitused_s__giseks from "./pages/Webpage13Soovitused_s__giseks";
import Webpage14Soovitused_talveks from "./pages/Webpage14Soovitused_talveks";
import Webpage15Maksa from "./pages/Webpage15Maksa";
import Webpage16K__psised from "./pages/Webpage16K__psised";
import Webpage17Ajurveeda_kehat____bid from "./pages/Webpage17Ajurveeda_kehat____bid";
import Webpage18Not_found from "./pages/Webpage18Not_found";
import Webpage19Terapeudid from "./pages/Webpage19Terapeudid";
import Webpage20Soovitused_suveks from "./pages/Webpage20Soovitused_suveks";

var baseURL = 'https://my.prana.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiOWFhMTQ3OTYxYmI3NzE1MjA0MTc5YWI3Zjk0NGI4ZmM3YWQ3NTI2NzRlNzhiYTMzZGJlYzkzNTY4OTgwYjM4ZDljMTdmOTdmZDQ0NDNjOWIiLCJpYXQiOjE3Mzk1NDU0NjEuNjIzMjQyLCJuYmYiOjE3Mzk1NDU0NjEuNjIzMzA3LCJleHAiOjE3Mzk2MzE4NjEuNjE3NjYyLCJzdWIiOiI2NSIsInNjb3BlcyI6W119.o6LuSTnAAqADvzdCPuL4Tvjei6Fe6QHR8hskZ1TQAREM1XRTBz2FSXApmu1KJtrXLmBWUKqDGFfF_WWvdGLIbOQdZBdRJpsMX62v9aNbpO95WCGa2DiDtnzPI1tSJ6jEkOO_aY1vX8R7H4QEQMC3vSR59LHUwSXJnDFEVT6BL6tNstG7Dsn4mS5Bo14WfTl4YlGiwcu0drzu_xpOaN50AE6i1YRoef0AxjQ2uCqaZoK9c5Ifz0nzC-mIPjFXD2e9-R4jr9_bodwKrPlOEEeSCME4xO3ivN1F-QaKrdkIe36L_4VWUwspgwUxW2r6XKrFPvvjWCoJosF9YIXJ_cc_6rZ-0zO-aW-ofdVvA9kuhB80T_595xFCzDrYf-GbhyT50eKPE_nlvPV3v0ijBbT7lxJpkcFWIn-CBZyMSjWOJlqeLJFDakCuRDXy45L92d_FeiIvyhiNQNlpv2syI8rUbBtvdM5OLd9zqYU5xD2teqyXXvFvAH0AQXOj4L9-jiZ-O83f0Zrt1q-2hQhrlqBisrWnZjjY9MQMJuHqKiRGwYCTosasrT76kvI-gpt0Z-3P37nxfptr38F5KbwqeDVYl2XE85Kkc94lYNLYfACHiO2qDwV2wPUdIY0unjjFTYSh8brd4fbsUVgikXkNXuAv1bMF1Yuv4vBaHudE0i_JmHk',
    'Access-api-key': "eyJpdiI6ImR6Z1hSdSs4ZzJkRGVabC9Rc1JMWkE9PSIsInZhbHVlIjoiQzRnL2RUQm5HdzBoZmM0WWIweDd6QT09IiwibWFjIjoiMzk1ZDhjMzgzOGZlODcwMDJmNjhhMmFiMzUyYWI3YjdhMWZlNTQ2OGU2NjljNGQyZWYzYjI1NGExODg2ZjE2MSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6ImRRZ2V0YWRCMTIzT201bmVRQ0E2U0E9PSIsInZhbHVlIjoiN2FJQ3h0dkQ3SXlWdTZlRmVXbGN4QT09IiwibWFjIjoiZjM5MzZmNmMwOGUwMjM1NzI5M2ZmNThkZjcxNGEyYTc1ZjU3NGFkOWM5ZGU1Yjg3NjhlNTViMzRkMjYyZTJlZSIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-NDMXWK5"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/tooted" element={<Webpage3Tooted />} exact />;
                                                                    <Route path="/et/ayurveda" element={<Webpage4Ajurveeda />} exact />;
                                                                    <Route path="/et/contact" element={<Webpage5KONTAKT />} exact />;
                                                                    <Route path="/et/consultation" element={<Webpage6Konsultatsioon />} exact />;
                                                                    <Route path="/et/therapy" element={<Webpage7Teraapiad />} exact />;
                                                                    <Route path="/et/toode/:id" element={<Webpage8Toode />} exact />;
                                                                    <Route path="/et/ayurveda/recommend" element={<Webpage9Ajurveeda_soovitab />} exact />;
                                                                    <Route path="/et/terms" element={<Webpage10M____gitingimused />} exact />;
                                                                    <Route path="/et/privacy" element={<Webpage11Privaatsuspoliitika />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSpring" element={<Webpage12Soovitused_kevadeks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalAutumn" element={<Webpage13Soovitused_s__giseks />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalWinter" element={<Webpage14Soovitused_talveks />} exact />;
                                                                    <Route path="/et/checkout" element={<Webpage15Maksa />} exact />;
                                                                    <Route path="/et/cookiePolicy" element={<Webpage16K__psised />} exact />;
                                                                    <Route path="/et/ayurveda/bodytypes" element={<Webpage17Ajurveeda_kehat____bid />} exact />;
                                                                    <Route path="/et/*" element={<Webpage18Not_found />} exact />;
                                                <Route path="*" element={<Webpage18Not_found />} exact />;
                                                                    <Route path="/et/terapeudid" element={<Webpage19Terapeudid />} exact />;
                                                                    <Route path="/et/ayurveda/seasonalSummer" element={<Webpage20Soovitused_suveks />} exact />;
                                                                
                                            <Route path="/product/:id" element={<Redirect to="/toode/:id" />} exact />;
                                            <Route path="/products" element={<Redirect to="/tooted" />} exact />;
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}